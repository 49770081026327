import cn from 'classnames';

import arrow from '../../icons/arrow.svg';
import map from '../../icons/carbon-map.svg';
import feedback from '../../icons/codicon-feedback.svg';
import flash from '../../icons/flash.svg';
import phone from '../../icons/phone.svg';

export const Icon = ({ className, i, size, style, ...props }) => {
  const icons = {
    arrow,
    map,
    feedback,
    flash,
    phone,
  };
  return (
    <img
      src={icons[i]}
      alt={`Icon ${i}`}
      className={cn(className)}
      style={{ width: size, height: size, ...style }}
      {...props}
    />
  );
};
