/* eslint-disable */
// noinspection SpellCheckingInspection
import {useState} from 'react';
import {Outlet, useNavigate, useSearchParams} from 'react-router-dom';
import './layout.css';
import {useTranslation} from 'react-i18next';
import {Container} from 'react-bootstrap';
import {ThemeProvider} from 'styled-components';
import {GlobalStyles, NavBar} from './globalStyles';
import {redesignedDarkTheme} from './darkTheme/Theme';
// import { useLocalStorage } from '../hookServices/useLocalStorage';
import ReactGA from 'react-ga';
import {SVGLogo} from './svgComponents/SVGLogo';
import {
  useGetBalanceQuery,
  useGetInProgressOrdersCountQuery
} from '../redux/api';
import {getClientId} from '../hookServices/clientId';
import Navigation from '../components/Navigation/Navigation';

import {PWAInstallElement} from '@khmyznikov/pwa-install';

const pwa = new PWAInstallElement();

export default function Layout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  //todo replace this hack by redux
  const {
    data: freshBalance,
  } = useGetBalanceQuery(
      getClientId(), {pollingInterval: 7000}
  );
  const {
    data: inProgressOrdersCount,
  } = useGetInProgressOrdersCountQuery(
      getClientId(), {pollingInterval: 7000}
  );

  let toggleStatus = !open ? 'toggle-icon' : 'open toggle-icon ';

  const closeMenu = () => {
    setOpen(!open);
  };
  const {t} = useTranslation();

  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get('station');

  // const { endpoints } = api;
  // const { data: stationStatus } = endpoints.getStationStatus.useQueryState({
  //   stationNumber,
  // });

  // const [togglerStatus] = useLocalStorage('themeTogglerStatus', false);
  // const [, setTheme] = useLocalStorage('themeMode', 'light');
  const hasCharging = true;
  pwa.showDialog();

  // const darkModeSetter = () => {
  //   setTheme('dark');
  // };
  // const lightModeSetter = () => {
  //   setTheme('light');
  // };

  // useEffect(() => {
  //   if (togglerStatus === false) {
  //     if (stationStatus?.uiNightMode === false) {
  //       lightModeSetter();
  //     } else if (stationStatus?.uiNightMode === true) {
  //       darkModeSetter();
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [stationStatus?.uiNightMode]);

  return (
      // todo implement theme changes
      // <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <ThemeProvider theme={redesignedDarkTheme}>
        <>
          <pwa-install></pwa-install>
          <GlobalStyles/>
          <Container
              id="layout"
              fluid
              style={{
                ...{
                  fontFamily: 'var(--font-family)',
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#fff',
                  letterSpacing: '0.15em',
                },
                height: '100vh',
              }}
              className="justify-content-center ml-0 pl-0 mr-0 pr-0"
          >
            <NavBar id="layoutNavBar" className="fixed-top" expand="lg">
              <div className={'header-container'}>
                <div
                    className={'balance-container'}
                    onClick={() => {
                      ReactGA.event({
                        category: 'header',
                        action: 'history form header',
                      });
                      if (hasCharging) {
                        navigate('/history');
                      }
                    }}
                >
                  <div
                      className={inProgressOrdersCount > 0 ? 'blink_me' : null}>
                    <p className={'textBalance'}>
                      <>{freshBalance ? freshBalance / 100 : 0} {t(
                          'currency')}</>
                    </p>
                  </div>
                </div>

                <div className={'header-logo'}
                     onClick={() => navigate(`/list?station=${stationNumber}`)}>
                  {' '}
                  <SVGLogo/>
                </div>

                <button id="layoutBurgerBtn" onClick={closeMenu}
                        className={toggleStatus}/>
                <div id="layoutHeaderCollapsedMenu">
                  {open && <Navigation stationNumber={stationNumber}
                                       closeMenu={closeMenu}/>}
                </div>
              </div>
            </NavBar>

            <div style={{marginTop: '133px', marginBottom: '50px'}}>
              <Outlet/>
            </div>
          </Container>
        </>
      </ThemeProvider>
  );
}
