/* eslint-disable */
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ua: {
        translation: {
          btns: {
            start: ' годин',
            startKwt: ' кВт*г',
            startFree: 'Спробувати безкоштовно',
            startFullCharge: 'Швидкий Старт',
            getAllStationsStatus: 'Показати всі станції',
            getNearestStationsStatus: 'Станції поруч',
          },
          historyEmpty: 'Історія зарядок відсутня',
          title: 'Заряди 220 кілометрів за ніч',
          station: 'Порт  #',
          feedbacks: 'Відгуки',
          leaveFeedback: 'Залишити відгук',
          chargeLink: 'Зарядка',
          landingLink: 'Список станцій',
          landingLinkMap: 'Мапа станцій',
          contacts: "Зв'язатися з нами",
          chooseVolume: 'Зарядити на',
          history: 'Історія зарядок',
          offer: 'Умови користування',
          wt: 'кВт*г',
          powerWt: ' кВт',

          energy: 'кВт',
          energyKwh: ' кВт*г',
          balance: 'Баланс:',
          currency: ' грн',
          payedForKwh: 'Оплачено',
          spent: 'Витрачено',
          v: 'В',
          km: ' км',
          chargedCongrats: 'Вітаємо!',
          chargedkWt: 'Заряджено: ',
          startNew: 'Хочу ще раз',
          power: 'Потужність',
          powerExceededError: 'Будь ласка, зменшіть потужність і спробуйте ще раз:',
          onlineStatus: 'Офлайн',
          powerKm: 'км/год',
          charging: 'Заряджено',
          voltage: 'Вольтаж',
          chargingSpeed: 'Швидкість заряду',

          allStationsStatusUnavailable: 'Наразі не доступно',
          getBloodyLitersSaved: 'Реінкарновано літрів',
          bloodyLitersLink: 'https://youtu.be/T0wK0VPy-V0?si=c9daReLH709PYE9q&t=83',
          noNearestStations: 'Поряд з вами станцій не виявлено',
          getDirection: 'Отримати маршрут',
          route: 'Маршрут',
          photo: 'Фото локації',
          distanceKm: ' км',
          distanceM: 'м',
          locationError: 'Увімкніть доступ до місцезнаходження в налаштуваннях браузера.',
          toHomePage: 'Станції поруч',

          login: 'Увійти з Google',
          loginTitle: 'Вітаємо',
          loginText: 'Увійдіть, щоб продовжити',

          logout: 'Вийти',

          userInfoName: 'Уввійшли як',

          historyPage: {
            station: 'Порт#',
            amountS: 'Тривалість зарядки',
            price: 'Витрачено',
            spendEnergy: 'Витречено енергії',
            started: 'Старт зарядки',
            finished: 'Закінчено',
            refund: 'Повернуто',
            balance: 'Баланс',
            state: 'Статус',
            timeHours: 'год',
            timeMinutes: 'хв',
          },

          stopButton: 'Зупинити',

          qrScanner: {
            nameButton: 'Сканувати QR-code',
            backButton: 'Назад',
            cameraPermissionAlert: 'Будь ласка, дозвольте доступ до камери та оновить сторінку',
          },

          errorStart: {
            alertMessagePort: `Помилка старту на Порту # {{portNumber}}`,
            alertMessage: 'Будь ласка спробуйте ще раз або зателефонуйте в службу підтримки:'
          },

          error: 'Помилка',
          errorDevHeader: 'Помилка серверу',
          errorDevBody: 'Ми вже вирішуємо проблему і скоро повернемось :)',
          errorOfflineHeader: 'Порт #<1>{{stationNumber}}</1> офлайн :(',
          errorOfflineBody: 'Спробуйте, будь ласка, пізніше',
          errorPayHeader: 'Оплата недоступна!',
          errorPayBody: 'Ми вже вирішуємо проблему і скоро повернемось :)',
          errorStartFailedHeader: 'Ой, халепа :(',
          errorStartFailedBody: '',
          errorFingerPrintUndefindHeader: 'Виявлений блокувальник реклами',
          errorFingerPrintUndefindBody: 'Будь ласка, вимкніть і спробуйте ще раз',
          errorFingerPrintUnavailableHeader: 'Безкоштовна зарядка вже використана',
          errorFingerPrintUnavailableBody: 'Здійсніть будь ласка оплату :)',

          stationOffline: 'Офлайн :(',

          costPerHour: ' грн за годину',
          costPerKwt: ' грн за кВт',
          costPerKwtAlt: ' грн за кВт',
          costPerKwtH: ' грн за кВт*г',

          readyForUse: 'Вільний!',

          calibration: 'Відкалібруйте результат!',
          enterYourKm: 'Будь ласка, введіть заряджені кілометри',
          sendKm: 'Калібрувати',
          calibratedKm: 'Ваш результат відкалібровано',
          btnRepeat: 'Повторити',

          featureInProgress: 'Чекайте в наступному оновленні :)',

          back: 'Повернутись',
          close: 'Закрити',
          delayedStart: 'Зарядка почнеться о <1>{{openFrom}}</1>',
          beforePayInfo: 'Невикористані ДРІБНІ ЗАЛИШКИ будуть накопичуватися на Вашому балансі.',
          beforeStartInfo: 'Оплатити з балансу?',
          cancelDelayedStart: 'Відмінити',
          delayedInfo: 'Працює з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',
          leftBeforeStart: 'До старту залишилося:',
          startAt: 'Почнеться о <1>{{openFrom}}</1>',
          stationCardInfo: 'з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',

          offerTitle: 'ПУБЛІЧНИЙ ДОГОВІР  ПРО НАДАННЯ ПОСЛУГ З ЗАРЯДКИ ЕЛЕКТРОТРАНСПОРТУ  (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ).',
          contents: 'Дата публікації: 09.08.2024',
          callUs: 'Зателефонувати',
          contactUs: "Зв'язатися з нами",
          instagram: 'Інстаграм',
          telegram: 'Телеграм',
          whatsApp: 'Ватсап',
          tapCall: 'Зателефонувати',
          tapChatBot: 'Отримати допомогу через чат',
          telHlib: 'Гліб',
          telMax: 'Макс',
          notFound: 'Сторінка не знайдена',
          btnPay: 'Оплатити',
          btnPayFor: 'Старт',
          overload: {
            waitForLink: 'Зачекайте секунду',
            gettingLink: 'Отримуємо посилання для оплати',
            successChecked: 'Вийшло',
            letsCharge: 'Погнали заряджати',
            checking: 'перевіряємо навантаження',
            overloadDetected: 'Виявлено перевантаження',
            slowdown: 'Будь ласка, зменшіть напругу до',
            repeat: 'і спробуйте ще раз',
          },
          footer: {
            part1: 'Всі права захищені',
            part2: 'Розроблено',
            part3: 'з ♥ до електромобілів',
          },

          b2bHeader: 'Для бізнесу',
          microgridHeader: 'Енергонезалежність',
          b2bNoStationsHeader: 'Ви ще не маєте станцій',
          b2bNoStationsBody: "Зв'яжіться з нами для замовлення",
          months: {
            january: 'Січень',
            february: 'Лютий',
            march: 'Березень',
            april: 'Квітень',
            may: 'Травень',
            june: 'Червень',
            july: 'Липень',
            august: 'Серпень',
            september: 'Вересень',
            october: 'Жовтень',
            november: 'Листопад',
            december: 'Грудень',
          },
        },
      },
      en: {
        translation: {
          energy: 'kWh',
          energyKwh: 'kWh',
          btns: {
            start: ' hours',
            startKwt: ' kWh',
            startFree: 'Try for free',
            startFullCharge: 'Just Start',
            getAllStationsStatus: 'Show all stations',
            getNearestStationsStatus: 'Show other stations',
          },
          historyEmpty: 'Charging history is empty',
          title: 'Charge 220 kilometers overnight',
          station: 'Port  #',
          feedbacks: 'Feedbacks',
          leaveFeedback: 'Leave a feedback',
          chargeLink: 'Charging',
          landingLink: 'All stations',
          landingLinkMap: 'Map',
          contacts: 'Contact us',
          chooseVolume: 'Pay for',
          history: 'History',
          offer: 'Terms and conditions',
          wt: 'kWh',
          powerWt: ' kW',
          powerKwh: ' kWh',
          balance: 'Balance:',
          currency: ' uah',
          payedForKwh: 'Paid',
          spent: 'Spent',
          v: 'V',
          km: ' km',
          chargedCongrats: 'Congrats!!!',
          chargedkWt: 'Your car has been charged by: ',
          startNew: 'Go again',
          power: 'Power',
          powerExceededError: 'Please reduce power and try again:',
          onlineStatus: 'Offline',
          powerKm: 'km/h',
          charging: 'Charged',
          voltage: 'Voltage',
          chargingSpeed: 'Charging speed',

          allStationsStatusUnavailable: 'Unavailable now',
          getBloodyLitersSaved: 'Bloody liters saved',
          bloodyLitersLink: 'https://youtu.be/mk-LnUYEXuM?si=BFE0nuqLCCcU5P6o&t=95',
          noNearestStations: 'No stations found near you',
          getDirection: 'Get direction',
          route: 'Navigate',
          photo: 'Photo',
          distanceKm: ' km',
          distanceM: 'm',
          locationError: 'Please enable location access in your browser settings.',
          toHomePage: 'All stations',

          login: 'Log In with Google',
          loginTitle: 'Welcome',
          loginText: 'Log in to continue',

          logout: 'Log out',

          historyPage: {
            station: 'Port#',
            amountS: 'Duration',
            price: 'Price',
            spendEnergy: 'Spend energy',
            started: 'Start',
            finished: 'Finished',
            refund: 'Refunded',
            balance: 'Balance',
            state: 'Status',
            timeHours: 'hours',
            timeMinutes: 'minutes',
          },

          stopButton: 'Stop',

          qrScanner: {
            nameButton: 'Scan QR-code',
            backButton: 'Back',
            cameraPermissionAlert: 'Please allow camera in your browser permissions and reload',
          },

          userInfoName: 'Logged in as',

          errorStart: {
            alertMessage: `Error to start port # {{portNumber}}. 
            Please try again later or call us`,
          },

          error: 'Error',
          errorDevHeader: 'Server error.',
          errorDevBody: 'We are reinventing and will be back soon :)',
          errorOfflineHeader: 'Port #<1>{{stationNumber}}</1> is offline :(',
          errorOfflineBody: 'Please, try again later',
          errorPayHeader: 'Payment is unavailable!',
          errorPayBody: 'Sorry! We are reinventing and will be back soon :)',
          errorStartFailedHeader: 'Oops, something went wrong :(',
          errorStartFailedBody: '',
          errorFingerPrintUndefindHeader: 'Ad block detected',
          errorFingerPrintUndefindBody: 'Please, turn it off and try again',
          errorFingerPrintUnavailableHeader: 'Free charge is unavailable',
          errorFingerPrintUnavailableBody: 'Please pay:)',

          stationOffline: 'Offline :(',

          costPerHour: ' uah per hour',
          costPerKwt: ' uah per kWh',
          costPerKwtAlt: ' uah/kWh',
          costPerKwtH: ' uah per kWh',

          readyForUse: 'Ready!',

          calibration: 'Calibrate result!',
          enterYourKm: 'Please, enter your km',
          sendKm: 'Calibrate',
          calibratedKm: 'Your result was calibrated successfully',
          btnRepeat: 'Try Again',

          featureInProgress: 'Coming soon :)',

          back: 'Back',
          close: 'Close',

          delayedStart: 'Charging will begin at <1>{{openFrom}}</1>',
          beforePayInfo: 'Leftovers will be collected on your balance.',
          beforeStartInfo: 'Pay from balance?',
          cancelDelayedStart: 'Cancel',
          delayedInfo: 'Works from <1>{{openFrom}}</1> till <1>{{closedFrom}}</1>',
          leftBeforeStart: 'Will start in:',
          startAt: 'Will start at <1>{{openFrom}}</1>',
          stationCardInfo: 'from <1>{{openFrom}}</1> to <1>{{closedFrom}}</1>',

          callUs: 'Call Us',
          contactUs: 'Contact us',
          instagram: 'Instagram',
          telegram: 'Telegram',
          whatsApp: 'WhatsApp',
          tapCall: 'Tap to call',
          tapChatBot: 'Telegram chat',
          telHlib: 'Hlib',
          telMax: 'Max',
          notFound: 'Page not found',
          btnPay: 'Pay',
          btnPayFor: 'Start for',
          overload: {
            waitForLink: 'Wait a second',
            gettingLink: 'Getting payment link',
            btnPay: 'Pay',
            successChecked: 'Success',
            letsCharge: 'Let`s charge it',
            checking: 'is overloaded checking',
            overloadDetected: 'Overload',
            slowdown: 'Please, slow down to',
            repeat: 'and try again',
          },
          footer: {
            part1: 'All rights reserved',
            part2: 'Made by',
            part3: 'with ♥ to Zero Emission Vehicles',
          },

          b2bHeader: 'B2B',
          microgridHeader: 'Blackout solutions',
          b2bNoStationsHeader: 'No stations are available yet',
          b2bNoStationsBody: 'Contact us to order',
          months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
          },
        },
      },
    },
  });

export default i18n;
