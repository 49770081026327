/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModalContacts from '../contactsPage/modal/ModalContacts';
import './ErrorPage.css';
import '../../components/buttons/mainBtn.css';
import {Container} from 'react-bootstrap';
import {Button} from '../../shared/ui';

const ErrorPage = ({ errorHeader, errorBody, startAgain, stationNumber }) => {
  const [isStartAgain, setIsStartAgain] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (startAgain === true) {
      setIsStartAgain(true);
    }
  }, [startAgain]);

  return (
    <Container id="errorPage" fluid="lg" className="d-flex align-items-center mt-3">
      <div className="mx-auto">
        <p id="errorPageHeader" className="title">
          {errorHeader}
        </p>
        {/*<p id="errorPageBody" className="title">*/}
        {/*  {t("station")} {stationNumber}*/}
        {/*</p>*/}

        {isStartAgain ? (
            <div className="mainBtnText">
              <br/>
              <p>
                <Button className={'mainBtnText'} link={`/start?station=${stationNumber}`}>
                  {t('startNew')}
                </Button>
              </p>
            </div>
        ) : (
            <div className="mainBtnText">
              <br/>
              <p>
                <Button className={'mainBtnText'} onClick={() => setModalOpen(true)}>
                  {t('contactUs')}
                </Button>
              </p>
            </div>
        )}
        <div className="mainBtnText">
          <br/>
          <p>
            <Button className={'mainBtnText'} link={`/`}>
              {t('btns.getNearestStationsStatus')}
            </Button>
          </p>
        </div>
      </div>

      <div id="errorPageModalContainer" style={{ textAlign: 'left' }}>
        <ModalContacts modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </Container>
  );
};

export default ErrorPage;
