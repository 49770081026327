/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './Map.css';
import * as maptilersdk from '@maptiler/sdk';
import {api} from "../../redux/api";
import ReactGA from "react-ga";

export default function Map({ports}, uiId) {
  const [getTokaStations] = api.useLazyGetTokaStationsQuery();
  const [getUgvStations] = api.useLazyGetUgvStationsQuery();
  const [getEcofactorStations] = api.useLazyGetEcofactorStationsQuery();
  const [getG2YStations] = api.useLazyGetG2YStationsQuery();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 31.223;
  const lat = 45.454;
  const zoom = 4;
  //todo extract to github secrets
  const API_KEY = 'fysAbJpnonqUYEipElqb';
  maptilersdk.config.apiKey = API_KEY;

  useEffect(() => {
    if (map.current) {
      return;
    } // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.STREETS,
      // navigationControl: false,

      center: [lng, lat],
      zoom: zoom,
      // geolocate: maptilersdk.GeolocationType.POINT,

      // geolocateControl: false,
      // navigationControl: false,

      maptilerLogo: false,
      scaleControl: true,
    });

    drawPorts();

    drawToka();
    drawUgv();

    drawEcofactor();
    drawG2Y();

  }, [API_KEY, lng, lat, zoom]);

  function drawPorts() {
    ports?.forEach(function (port) {
      var marker = {
        'type': 'Feature',
        'properties': {
          'message': 'Baz',
          'iconSize': [40, 40]
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [
            parseFloat(`${port.longitude}`),
            parseFloat(`${port.latitude}`),
          ],
        }
      }

      var el = document.createElement('div');
      el.style.width = '30px';
      el.style.height = '30px';

      el.className = port?.job?.state === 'IN_PROGRESS' ? 'blink_me' : null;
      el.style.backgroundImage =
          port.online ? `url(/port.svg)` : `url(/portOffline.svg)`;
      el.addEventListener('click', function () {
        window.open(`/start?station=${port.number}`);
      });
      if (port?.name?.includes('inService')) {
        el.style.backgroundImage = `url(/inService.svg)`
        return
      }

      if (port?.name.includes('viwatt-')) {
        el.style.width = '20px';
        el.style.height = '20px';
        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          window.open(port?.deviceId)
        });
      }


      new maptilersdk.Marker({element: el})
      .setLngLat(marker.geometry.coordinates)
      .addTo(map.current);
    });

  }
  function drawToka() {
    const getItems = async () => {
      const items_fetched = await getTokaStations('uiId');
      let ports = items_fetched.data;

      ports?.forEach(function (port) {
        var marker = {
          'type': 'Feature',
          'properties': {
            'message': 'Baz',
            'iconSize': [20, 20]
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [
              parseFloat(`${port.longitude}`),
              parseFloat(`${port.latitude}`),
            ],
          }
        }

        var el = document.createElement('div');
        el.style.width = '20px';
        el.style.height = '20px';

        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          ReactGA.event({
            category: `MAP_ADD_CLICK_TOKA`,
            action: port?.deviceId,
          });
          window.open(port?.deviceId)
        });

        el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

        new maptilersdk.Marker({element: el})
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
      });
    };

    getItems()
  }
  function drawUgv() {
    const getItems = async () => {
      const items_fetched = await getUgvStations('uiId');
      let ports = items_fetched.data;

      ports?.forEach(function (port) {
        var marker = {
          'type': 'Feature',
          'properties': {
            'message': 'Baz',
            'iconSize': [20, 20]
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [
              parseFloat(`${port.longitude}`),
              parseFloat(`${port.latitude}`),
            ],
          }
        }

        var el = document.createElement('div');
        el.style.width = '20px';
        el.style.height = '20px';

        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          ReactGA.event({
            category: `MAP_ADD_CLICK_UGV`,
            action: port?.deviceId,
          });
          window.open(port?.deviceId)
        });
        el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

        new maptilersdk.Marker({element: el})
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
      });
    };

    getItems()
  }
  function drawEcofactor() {
    const getItems = async () => {
      const items_fetched = await getEcofactorStations('uiId');
      let ports = items_fetched.data;

      ports?.forEach(function (port) {
        var marker = {
          'type': 'Feature',
          'properties': {
            'message': 'Baz',
            'iconSize': [20, 20]
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [
              parseFloat(`${port.longitude}`),
              parseFloat(`${port.latitude}`),
            ],
          }
        }

        var el = document.createElement('div');
        el.style.width = '20px';
        el.style.height = '20px';

        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          ReactGA.event({
            category: `MAP_ADD_CLICK_EF`,
            action: port?.deviceId,
          });
          window.open(port?.deviceId)
        });
        el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

        new maptilersdk.Marker({element: el})
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
      });
    };

    getItems()
  }
  function drawG2Y() {
    const getItems = async () => {
      const items_fetched = await getG2YStations('uiId');
      let ports = items_fetched.data;

      ports?.forEach(function (port) {
        var marker = {
          'type': 'Feature',
          'properties': {
            'message': 'Baz',
            'iconSize': [20, 20]
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [
              parseFloat(`${port.longitude}`),
              parseFloat(`${port.latitude}`),
            ],
          }
        }

        var el = document.createElement('div');
        el.style.width = '20px';
        el.style.height = '20px';

        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          ReactGA.event({
            category: `MAP_ADD_CLICK_G2Y`,
            action: port?.deviceId,
          });
          window.open(port?.deviceId)
        });
        el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

        new maptilersdk.Marker({element: el})
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
      });
    };

    getItems()
  }

  function isIos() {
    return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on isIos 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
      <div className="map-wrap">
        <div ref={mapContainer} className="map"/>
      </div>
  );
}
