import React, { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { api } from '../../../redux/api';
import { getGeolocationWithPermission } from '../../../hookServices/getGeolocationWithPermission';
import { getCookie } from '../../../hookServices/cookiesManager';
import Spinner from '../../loaders/globalSpinner/Spinner';

export default function StationNumberLayout({ fingerPrint }) {
  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get('station');

  const [station, setStation] = useState();
  const [isFetchingStation, setIsFetchingStation] = useState(true);
  const [isLoadingStation, setIsLoadingStation] = useState(true);
  const [isErrorStation, setIsErrorStation] = useState(false);

  const [getDeviceStation] = api.useLazyGetDeviceStationQuery();

  const getDeviceStationInfo = async position => {
    const {
      data: station,
      isFetching: isFetchingStation,
      isLoading: isLoadingStation,
      isError: isErrorStation,
    } = await getDeviceStation({
      stationNumber,
      lat: position ? position.latitude : '',
      lon: position ? position.longitude : '',
      fingerPrint,
    });
    if (isLoadingStation || isFetchingStation) {
      setIsFetchingStation(isFetchingStation);
      setIsLoadingStation(isLoadingStation);
    }
    if (!isFetchingStation && !isLoadingStation && station) {
      setStation(station);
      setIsFetchingStation(isFetchingStation);
      setIsLoadingStation(isLoadingStation);
    } else if (isErrorStation) {
      setIsErrorStation(isErrorStation);
      setIsFetchingStation(isFetchingStation);
      setIsLoadingStation(isLoadingStation);
    }
  };

  function handleGeolocationSuccess(position) {
    getDeviceStationInfo(position?.coords);
  }
  function handleGeolocationError() {
    getDeviceStationInfo();
  }
  function getIfError() {
    getDeviceStationInfo();
  }

  function getGeolocation() {
    const position = getCookie('userLocation');
    if (position) {
      getDeviceStationInfo(position);
    } else {
      getGeolocationWithPermission(getIfError, handleGeolocationError, handleGeolocationSuccess);
    }
  }

  useEffect(() => {
    getGeolocation();
    //eslint-disable-next-line
  }, []);

  if (isLoadingStation || isFetchingStation) {
    return <Spinner />;
  }

  const openFrom = station?.openFrom.slice(0, 5);
  const closedFrom = station?.closedFrom.slice(0, 5);
  const isTwentyFourHour = station?.openFrom === '00:00:00' && station?.closedFrom === '00:00:00';

  return (
    <Outlet
      context={{
        station,
        stationNumber,
        openFrom,
        closedFrom,
        isTwentyFourHour,
        isFetchingStation,
        isLoadingStation,
        isErrorStation,
      }}
    />
  );
}
