/* eslint-disable */
import ReactGA from 'react-ga';
import {api} from '../../redux/api';

export const startCharging = () => {
  const [getPaymentLink] = api.useLazyGenerateCheckoutLinkKwtQuery();
  const [startFree] = api.useLazyDeviceStartQuery();

  const startChargingFree = async stationNumber => {
    const { error } = await startFree({ stationNumber });
    ReactGA.event({
      category: 'startChargingFree',
      action: 'start free',
    });
    if (error) return error?.data?.data;
    return 'success';
  };
  const openPaymentLink = async (stationNumber, kwt, autoStart, setError) => {
    kwt = Number(kwt);

    let win = window.open();
    const { data, isSuccess, error } = await getPaymentLink({
      stationNumber,
      kwt,
      autoStart,
    });
    if (!win) {
      return;
    }
    if (error) {
      setError(error.status);
      win.close();
    }
    if (isSuccess) {
      win.location = `${data?.pageUrl}`;
      localStorage.setItem('invoiceId', data?.invoiceId);
      return data?.invoiceId;
    }
  };
  return {
    startChargingFree,
    openPaymentLink,
  };
};
