/* eslint-disable */
import Box from "@mui/material/Box";
import './historyPage.css';
import {useEffect, useState} from 'react';
import {useGetHistoryDataQuery} from '../../redux/api';
import {Container, Row} from 'react-bootstrap';
import {Typography} from '@mui/material';
import {
  AccordionCustom,
  AccordionDetailsCustom,
  AccordionSummaryCustom,
  ColCustom,
  ExpandMoreIconCustom,
  HistoryLink,
} from '../../components/globalStyles';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {plugTypeMaping} from '../../utils/utils';
import {getClientId} from '../../hookServices/clientId';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import {Button, Card} from "../../shared/ui";
import {LoadingSm} from "../../components/loaders/localLoading/LocalLoading";

moment.locale('ua', {
  monthsShort: 'Cіч_Лют_Бер_Кві_Тра_Чер_Лип_Сер_Вер_Жов_Лис_Гру'.split('_'),
});

export default function HistoryPage() {
  const getLanguage = localStorage.getItem('i18nextLng');
  // eslint-disable-next-line no-unused-vars
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage);
  const {data} = useGetHistoryDataQuery(getClientId());
  const historyData = data;
  const formatTime = 'DD MMM HH:mm';

  const {t} = useTranslation();

  //todo do not copypaste
  // const checkInvoiceId = localStorage.getItem('invoiceId') === invoiceId;
  const urlProtocol = window.location.protocol;
  const urlHost = window.location.host;
  const [hideSpinner, setHideSpinner] = useState(true);

  function stopProcessCharging(portNumber) {
    setHideSpinner(false)
    fetch(`${urlProtocol}//${urlHost}:8080/api/device/v2/stop`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({clientId: getClientId()}),
    })
    .then(response => {
      if (!response.ok && response.status !== 404) {
        throw new Error('http error!');
      }
      window.location.href = `/charging?station=${portNumber}`
    })
    .catch(e => {
      throw new Error(e);
    });
  }

  useEffect(() => {
    setCurrentLanguage(getLanguage);
    moment.locale(getLanguage);
  }, [getLanguage]);

  if (!historyData) {
    return <Spinner/>;
  }

  if (historyData.length === 0) {
    return (
        <div fluid="lg">
          <Box sx={{m: "100% 5%"}}>
            <Card
                variant="invert"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBlock: "10px"
                }}
            >
              <span style={{
                fontSize: "28px",
                letterSpacing: "2px"
              }}>
                {t('historyEmpty')}
              </span>
            </Card>
          </Box>
        </div>
    )
  }

  return (
      <Container className={'p-0 m-0 pt-3 pb-5'} style={{marginTop: '50px'}}>
        {historyData.map(
            (
                {
                  plugNumber: portNumber,
                  startedOn,
                  priceInCents,
                  durationSeconds,
                  energyWth,
                  finishedOn,
                  refundedCents,
                  balanceCents,
                  state,
                  address,
                  plugType,
                },
                index
            ) => {
              function defineTime() {
                const remainingHours = Math.floor(durationSeconds / 3600);
                const remainingMinutes = Math.round(
                    (durationSeconds % 3600) / 60
                );
                return (
                    <>
            <span style={{fontSize: '1em'}}>
              {remainingHours} {t(
                'historyPage.timeHours')} {remainingMinutes} {t(
                'historyPage.timeMinutes')}
            </span>
                    </>
                );
              }

              return (
                  <AccordionCustom key={index} className={''}>
                    <AccordionSummaryCustom
                        expandIcon={<ExpandMoreIconCustom/>}
                        aria-controls={'panel1a-content'}
                        id={'panel1a-header'}
                    >
                      <Typography
                          className={'w-100 d-flex justify-content-between'}
                          style={{fontSize: '1em'}} // Doubling the font size
                      >
                        <span>{moment(startedOn).format(formatTime)} </span>
                        {state === 'TOP_UP_BALANCE' ? (
                            <span style={{color: 'green'}}>
                  + {refundedCents / 100} {t('currency')}
                </span>
                        ) : null}
                        <div className={state === 'IN_PROGRESS' ? 'blink_me'
                            : null}>
                <span
                    style={{
                      marginLeft: '5px',
                      animation: 'blink-animation',
                      fontSize: '1em', // Doubling the font size
                    }}
                >
                  {(energyWth / 1000).toFixed(1)} {t('wt')}
                </span>
                        </div>
                      </Typography>{' '}
                    </AccordionSummaryCustom>
                    <AccordionDetailsCustom>
                      <Row>
                        <ColCustom className={'justify-content-around'}>
                          <HistoryLink
                              className={'historyLink justify-content-around'}
                              to={`/start?station=${portNumber}`}>
                            <div style={{fontSize: '3em'}}>
                              {t('historyPage.station')} {portNumber} <br/>
                              {plugType && `(${plugTypeMaping[plugType]})`}
                            </div>
                          </HistoryLink>
                        </ColCustom>
                      </Row>
                      <Row className={'cols-2'}>
                        <ColCustom style={{fontSize: '1em'}}>
                          {t('historyPage.price')}:{' '}
                          {priceInCents / 100} {t('currency')}
                        </ColCustom>
                        <ColCustom style={{fontSize: '1em'}}>
                          {balanceCents === 0 ?
                              t('historyPage.refund'):
                              t('historyPage.balance')}:
                          <div style={{color: "violet"}}>
                            {balanceCents === 0 ?
                                refundedCents / 100
                                : balanceCents / 100}
                          </div>
                          {t('currency')}
                        </ColCustom>
                      </Row>
                      <Row>
                        <ColCustom className={'justify-content-around'}>
                          <div className={state === 'IN_PROGRESS' ? 'blink_me'
                              : null}>
                  <span style={{fontSize: '1.5em'}}>
                    {t('historyPage.amountS')}: {defineTime()}
                  </span>{' '}
                          </div>
                        </ColCustom>
                      </Row>
                      {address && (
                          <Row>
                            <ColCustom className={'justify-content-around'}
                                       style={{fontSize: '1em'}}>
                              {`(${address})`}
                            </ColCustom>
                          </Row>
                      )}
                      {state === 'IN_PROGRESS' && (
                          <Row>
                            <ColCustom className={'justify-content-around'}>
                              <Row className={'m-2'}>
                                {hideSpinner && (
                                    <Button onClick={() => stopProcessCharging(
                                        portNumber)}>
                                  <span className="mainBtnText">{t(
                                      'stopButton')}</span>
                                    </Button>
                                )}
                                {!hideSpinner && <LoadingSm/>}
                              </Row>
                            </ColCustom>
                          </Row>
                      )}
                    </AccordionDetailsCustom>
                  </AccordionCustom>
              );
            }
        )}
      </Container>
  );
}
